import { CoiningKit } from 'src/app/core/models/product/coining_kit.model';
import { Val1Val2Val3 } from 'src/app/core/models/product/val1_val2_val3';
import { Val1Val2 } from 'src/app/core/models/product/val1_val2';
import { Val1Val2Type } from 'src/app/core/models/product/val1_val2_type';
import { Val1Val2Val3Val4 } from 'src/app/core/models/product/val1_val2_val3_val4';

export interface ValveTechData {
  id: number | null;
  idRevision: number | null;
  auditUser: string | null;
  maximumOperatingPressure: number;
  maximumFlow: number;
  minimumPullInVoltage: number;
  maximumInternalLeakage1: Val1Val2Type;
  maximumInternalLeakage2: Val1Val2Type;
  maximumInternalLeakage3: Val1Val2Type;
  maximumInternalLeakage4: Val1Val2Type;
  externalComponentTreatment: Val1Val2 | null;
  oringTemperatureRange1: Val1Val2 | null;
  oringTemperatureRange2: Val1Val2 | null;
  oringTemperatureRange2Allow: boolean;
  oringTemperatureRange3: Val1Val2 | null;
  oringTemperatureRange3Allow: boolean;
  oilTemperatureRange: Val1Val2 | null;
  viscosities: Val1Val2 | null;
  filtration: Val1Val2Val3 | null;
  installationTool: string | null;
  installationTorque: Val1Val2Val3 | null;
  pilotRatio: Val1Val2 | null;
  compensatorSpringAccuracy1: Val1Val2 | null;
  compensatorSpringAccuracy2: Val1Val2 | null;
  maximumCompensatedPriorityFlow1: Val1Val2 | null;
  maximumCompensatedPriorityFlow2: Val1Val2 | null;
  maximumCompensatedBypassFlow1: Val1Val2 | null;
  maximumCompensatedBypassFlow2: Val1Val2 | null;
  antiCavCrackingPressure: number | null;
  pressureSettingEstablished: number | null;
  reseatPressure: number | null;
  tighteningTorqueNut: Val1Val2Val3 | null;
  pressureSettingRange: Val1Val2 | null;
  tighteningTorqueBlindNut: Val1Val2Val3 | null;
  plasticTamperProofCap: string | null;
  sealKit: string | null;
  coil: string | null;
  coil2: string | null;
  idCoiningKit: number | null;
  techCoiningKit: CoiningKit | null;
  idCoiningKit2: number | null;
  techCoiningKit2: CoiningKit | null;
  weight: number | null;
  switchOnTime: number | null;
  switchOffTime: number | null;
  oilTestingCondition: string | null;
  maximumFlowThermalReliefValve: number | null;
  thermalReliefCrackPressure2To1: Val1Val2Val3Val4 | null;
  externalComponentTreatmentVal2Allow: boolean;
  coil2Allow: boolean;
  voltage: Val1Val2 | null;
  input: Val1Val2 | null;
  pwmFrequency: Val1Val2 | null;
  operatingRange: Val1Val2 | null;
  connector: string | null;
  protectionClass: string | null;
  maxCurrent: number | null;
  numElectricalContacts: string | null;
  power: number | null;
  power2: number | null;
  coilInsulationClass: Val1Val2 | null;
  wireInsulationClass: Val1Val2 | null;
  ed: number | null;
  supplyPowerTolerance: Val1Val2Val3 | null;
  ambientTemperatureRange1: Val1Val2 | null;
  ambientTemperatureRange2: Val1Val2 | null;
  ipCoilOringNut: number | null;
}

export const emptyValveTechData: ValveTechData = {
  id: null,
  idRevision: null,
  auditUser: null,
  maximumOperatingPressure: 0,
  maximumFlow: 0,
  minimumPullInVoltage: 0,
  maximumInternalLeakage1: {
    val1: null,
    val2: null,
    type: '@',
  },
  maximumInternalLeakage2: {
    val1: null,
    val2: null,
    type: '@',
  },
  maximumInternalLeakage3: {
    val1: null,
    val2: null,
    type: '@',
  },
  maximumInternalLeakage4: {
    val1: null,
    val2: null,
    type: '@',
  },
  externalComponentTreatment: {
    val1: 'Zn/Fe - standard (96h)',
    val2: 'Zn/Ni - standard (720h)',
  },
  oringTemperatureRange1: { val1: '-30', val2: '110' },
  oringTemperatureRange2: { val1: '-35', val2: '140' },
  oringTemperatureRange2Allow: false,
  oringTemperatureRange3: { val1: '-20', val2: '225' },
  oringTemperatureRange3Allow: false,
  oilTemperatureRange: { val1: '-30', val2: '110' },
  viscosities: { val1: '7.4', val2: '420' },
  filtration: { val1: '20', val2: '18', val3: '15' },
  installationTorque: { val1: '', val2: '', val3: '' },
  pilotRatio: { val1: null, val2: null },
  compensatorSpringAccuracy1: { val1: null, val2: null },
  compensatorSpringAccuracy2: { val1: null, val2: null },
  maximumCompensatedPriorityFlow1: { val1: null, val2: null },
  maximumCompensatedPriorityFlow2: { val1: null, val2: null },
  maximumCompensatedBypassFlow1: { val1: null, val2: null },
  maximumCompensatedBypassFlow2: { val1: null, val2: null },
  antiCavCrackingPressure: null,
  pressureSettingEstablished: null,
  reseatPressure: null,
  tighteningTorqueNut: { val1: null, val2: null, val3: null },
  pressureSettingRange: { val1: null, val2: null },
  tighteningTorqueBlindNut: { val1: null, val2: null, val3: null },
  plasticTamperProofCap: null,
  sealKit: null,
  coil: null,
  coil2: null,
  installationTool: null,
  idCoiningKit: null,
  techCoiningKit: null,
  idCoiningKit2: null,
  techCoiningKit2: null,
  weight: null,
  switchOnTime: null,
  switchOffTime: null,
  oilTestingCondition: null,
  maximumFlowThermalReliefValve: null,
  thermalReliefCrackPressure2To1: {
    val1: null,
    val2: null,
    val3: null,
    val4: null,
  },
  externalComponentTreatmentVal2Allow: true,
  coil2Allow: true,
  voltage: { val1: null, val2: null },
  input: { val1: null, val2: null },
  pwmFrequency: { val1: null, val2: null },
  operatingRange: { val1: null, val2: null },
  connector: null,
  protectionClass: null,
  maxCurrent: null,
  numElectricalContacts: null,
  power: null,
  power2: null,
  coilInsulationClass: { val1: null, val2: null },
  wireInsulationClass: { val1: null, val2: null },
  ed: null,
  supplyPowerTolerance: { val1: null, val2: null, val3: null },
  ambientTemperatureRange1: { val1: null, val2: null },
  ambientTemperatureRange2: { val1: null, val2: null },
  ipCoilOringNut: null,
};
